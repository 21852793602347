import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SingleSession from "../templates/singlesession"
import sessions from "../../data/sessions.json"

const BrowseAll = () => { 
  let id;
  let selectedSessions;

  if (typeof window !== `undefined`) {
    selectedSessions = JSON.parse(window.localStorage.getItem('selectedSessions'));    
  }

  if(selectedSessions) {
    let pending = sessions.data.filter(o=> !selectedSessions.some(i=> i.id === o.id));    
    if(pending[0]) {
      id = pending[0].id.toString();
    }    
  } else {
    id = sessions.data[0].id.toString();;
  }
  
  return(
  <Layout>
    <Seo title="Browse" />
    <React.Fragment>
        {(id) ? <SingleSession id={id} browsemode={true}></SingleSession>:<p>You have seen them all!</p>}
    </React.Fragment>
  </Layout>
)
}
export default BrowseAll